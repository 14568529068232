:root {
        --max-width: 800px;
        --border-radius: 12px;
        --foreground-rgb: 0, 0, 0;
        --background-start-rgb: 214, 219, 220;
        --background-end-rgb: 255, 255, 255;
        --accent-color: #c7f0a8;
        --background-start-rgb: 30, 30, 30;
        --background-end-rgb: 20, 20, 20;
}

@font-face {
        font-family: "Inter";
        src: url('/public/assets/fonts/Inter-Regular.woff2') format("woff2");
        font-style: normal;
        font-weight: 400;
        font-display: swap;
}

* {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        font-family: "Inter", sans-serif;
        -webkit-transition: all 0.1s ease;
        -o-transition: all 0.1s ease;
        transition: all 0.1s ease;
        text-wrap: pretty;
}

html,
body {
        max-width: 100vw;
        overflow-x: hidden;
        background-color: #222;
}

body {
        color: rgb(var(--foreground-rgb));
}

a {
        color: inherit;
        text-decoration: none;
}

button {
        background-color: transparent;
        border: none;
}

.main {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        /* padding: 6rem; */
        min-height: 100vh;
        color: #ddd;
        font-size: 13px;
        font-optical-sizing: auto;
        font-style: normal;
        font-variation-settings:
                "slnt" 0;
        text-wrap: pretty;
        letter-spacing: 0.25px;
        position: relative;
        scroll-behavior: smooth;
}

.main::after {
        content: "";
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        ---cube-color: "#111";
        background-image:
                linear-gradient(to right, #000 1px, transparent 1px),
                linear-gradient(to bottom, #000 1px, transparent 1px),
                linear-gradient(to right, transparent 50%, #000 50%, #000 52%, transparent 52%),
                linear-gradient(to bottom, transparent 50%, #000 50%, #000 52%, transparent 52%);
        background-size: 0.3dvw 0.3dvw;
        opacity: 0.1;
}

.center-container {
        position: relative;
        /* max-width: var(--max-width); */
        /* width: 100%; */
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: stretch;
        min-height: 100dvh;
        gap: 1rem;
        background-image: url("../../public/assets/images/mesh-gradient.avif");
        background-size: cover;
        /* background-color: #111; */
}

.center-container:after {
        content: "";
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        pointer-events: none;
}


.cool-container {
        z-index: 5;
        width: clamp(200px, 100%, 720px);
        min-height: 150px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 1.5px;
        height: 100dvh;
}

.cool-body {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: start;
        overflow-y: auto;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        /* background: -o-linear-gradient(315deg, #343434, #1f1f1f);
        background: linear-gradient(135deg, #343434, #1f1f1f); */
        border-radius: 11px;
        padding: 1rem;
        padding-block: 1rem;
        padding-top: 5rem;
        padding-bottom: 3rem;
}

section {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
}

.about {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        gap: 0.5rem;
        padding: 1rem;
        max-width: 700px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        align-items: center;
        /* background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(100px);
        border-radius: 8px;
        border:solid 1px #333; */
        animation: swipe-in 0.5s ease forwards;
        opacity: 0;
}

.about .my-name {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
}

.about .my-name .title {
        font-size: 1.5rem;
        font-weight: 400;
        color: #fff;
}

.about .my-name .sub-title {
        font-weight: 400;
        letter-spacing: 2px;
        color: var(--accent-color);
        font-size: 10px;
        box-shadow: 0px 0px 2px 0px var(--accent-color);
        padding: 2px 12px;
        border-radius: 99px;
        background-color: rgba(0, 0, 0, 0.5);
        border: solid 1px var(--accent-color);
}

.wave {
        animation-name: wave-animation;
        animation-duration: 2.5s;
        animation-iteration-count: infinite;
        transform-origin: 70% 70%;
        display: inline-block;
}

@keyframes wave-animation {
        0% {
                transform: rotate(0.0deg)
        }

        10% {
                transform: rotate(14.0deg)
        }

        20% {
                transform: rotate(-8.0deg)
        }

        30% {
                transform: rotate(14.0deg)
        }

        40% {
                transform: rotate(-4.0deg)
        }

        50% {
                transform: rotate(10.0deg)
        }

        60% {
                transform: rotate(0.0deg)
        }

        100% {
                transform: rotate(0.0deg)
        }
}


.email {
        display: flex;
        gap: 0.5rem;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 4px 12px;
        border-radius: 99px;
        border: solid 1px #222;
}

.about .my-image {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
}

.about .my-image-container {
        /* background: #999; */
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.4);
        overflow: hidden;
        outline: solid 2px #222;
        outline-offset: 0px;
}

.about .backgroud-spinner {
        position: absolute;
        width: 100%;
        height: 100%;
        outline: dashed 2px #ddd;
        outline-offset: -4px;
        animation: spin 15s linear infinite;
        border-radius: 50%;
        animation-play-state: paused;
        display: none;
}

.about:hover {
        .backgroud-spinner {
                outline-offset: 4px;
                animation-play-state: running;
        }
}

@keyframes spin {
        from {
                transform: rotate(0deg);
        }

        to {
                transform: rotate(360deg);
        }

}

.about .my-image-container img {
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
        display: block;
        -webkit-filter: saturate(1.25);
        filter: saturate(1.25);
        scale: 1.25;
}

.about .my-text {
        /* letter-spacing:0.5px; */
        line-height: 1.5rem;
        text-wrap: pretty;
        font-size: 14px;
        text-align: center;
}

.about .my-links {
        padding-top: 1rem;
}

.about .my-text p {
        max-width: 500px;
}

.svg.icon.black {
        -webkit-filter: invert(1) contrast(0.5);
        filter: invert(1) contrast(0.5);
}

.title {
        font-weight: 400;
        color: #fff;
}

.sub-title {
        margin-top: 0.25rem;
        font-weight: 400;
        color: var(--accent-color);
        line-height: 1.25rem;
}

.description {
        font-weight: 400;
        color: #aaa;
        padding-block: 0.5rem;
        display: block;
}

@media screen and (max-width: 500px) {
        .center-container {
                padding-block: 0;
        }

        .cool-container {
                border-radius: 0;
                padding: 0;
        }

        .cool-container .cool-body {
                border-radius: 0;
                min-height: 100dvh;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
        }
}

@media (hover:none) {
        .theme-toggle:hover {
                -webkit-filter: none;
                filter: none;
        }
}

.medium-zoom-overlay {
        z-index: 900;
}

.navbar {
        z-index: 999;
        position: fixed;
        top: 1rem;
        max-width: 95dvw;
}

.navbar ul {
        width: auto;
        display: flex;
        justify-content: space-evenly;
        gap: 0.5rem;
        align-items: center;
        padding: 0.5rem 0.5rem;
        height: 50px;
        border: solid 1px #222;
        border-radius: 99px;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
        list-style: none;

        .active-section {
                background-color: #eee;
                color: #111;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 0.5rem 1.5rem;
                width: 125px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                transform-origin: center;
                border-radius: 99px;
                /* height: 60px; */
        }
}

.navbar:hover {
        /* scale: 1.05; */
        will-change: transform;
        top: 0.85rem;

        ul {
                height: 55px;
                padding-inline: 0.75rem;
        }

}

.navbar ul li {
        /* text-transform: uppercase; */
        font-weight: bold;
        border-radius: 99px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 0.5rem 1rem;
                border-radius: 99px;
        }

        a:hover {
                background-color: rgba(255, 255, 255, 1);
                padding-inline: 1.25rem;
                color: #111;
                /* padding-inline: 1.25rem; */
        }

        &.active {
                background-color: #111;
                pointer-events: none;
        }
}

.navbar ul li:hover {
        color: #222;
}

.project-section {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        animation: swipe-in 0.5s;

        h1 {
                font-size: 1.5rem;
        }

        p {
                font-size: 14px;
        }


}

.separator {
        height: 1px;
        width: 200px;
        display: inline-block;
        /* background-color: white; */
        background: linear-gradient(to right,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 0.5) 20%,
                        rgba(255, 255, 255, 0.5) 80%,
                        rgba(255, 255, 255, 0) 100%);
}

.projects-grid {
        width: 100%;
        padding-block: 1rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        .featured {
                grid-column: span 2;

                .image-container {
                        width: 100%;
                        height: 300px;
                }
        }

        .project-card {
                text-align: left;
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                /* backdrop-filter: blur(10px); */
                border-radius: 12px;
                overflow: hidden;
                border: solid 1px #222;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
                padding: 0.25rem;
                animation: swipe-in 0.5s ease forwards;
                opacity: 0;

                .title {
                        font-size: 16px;
                }
        }

        .project-card:hover {
                img {
                        scale: 1.05;
                }
        }

        .image-container {
                width: 100%;
                min-width: 300px;
                min-height: 170px;
                max-height: 200px;
                overflow: hidden;
                border-radius: 8px;
                position: relative;
                background-color: rgba(0, 0, 0, 0.4);
                box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.5);

                .image-caption {
                        position: absolute;
                        display: inline-block;
                        bottom: 0px;
                        left: 50%;
                        transform: translateX(-50%);
                        right: 0;
                        font-size: 14px;
                        text-align: center;
                        background-color: rgba(0, 0, 0, 0.5);
                        padding: 0.5rem 1rem;
                        backdrop-filter: blur(100px);
                        border-radius: 12px 12px 0 0;
                        white-space: nowrap;
                        color: #eee;
                        border: solid 1px #333;
                        box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
                        border-bottom: 0;
                }

                img {
                        /* min-width: 100%; */
                        object-fit: cover;
                }
        }

        .project-card-body {
                padding: 1rem 0.5rem 0 0.5rem;
                display: flex;
                flex-direction: column;
                flex: 1;

                p {
                        color: #bbb;
                }
        }

        .sub-title {
                flex: 1;
        }

        .project-links {
                margin-block: 1rem 0.5rem;
                display: flex;
                gap: 0.5rem;
                justify-content: end;
                align-self: flex-end;

                a {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        gap: 0.5rem;
                        width: 36px;
                        height: 36px;
                        background-color: rgba(0, 0, 0, 0.25);
                        border-radius: 99px;
                        cursor: pointer;

                        .label {
                                display: none;
                        }
                }

                a:hover {
                        background-color: rgba(255, 255, 255, 1);
                        color: #111;
                        width: 100px;

                        .label {
                                display: inline-block;
                                font-weight: 600;
                        }
                }

                .external-link {
                        background-color: rgba(255, 255, 255, 1);
                        color: #111;
                }

        }
}

@media screen and (max-width: 600px) {
        .projects-grid {
                grid-template-columns: repeat(1, 1fr);
        }
}

@keyframes swipe-in {
        0% {
                transform: translateY(50px);
                opacity: 0;
        }

        100% {
                transform: translateX(0%);
                opacity: 1;
        }
}

.tech-stack {
        text-align: center;
        margin-top: 1rem;
        animation: swipe-in 0.5s ease-in-out forwards;
        opacity: 0;
        max-width: 600px;

        h1 {
                font-size: 18px;
                font-weight: normal;
                color: #eee;
                margin-bottom: 0.25rem;
        }
}

.tech-stack-items {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        margin-block: 1rem;
        /* align-items: center; */
        justify-content: center;
}

.tech-stack-item {
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 80px;
        aspect-ratio: 1;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        border: solid 1px #222;
        animation: swipe-in 0.5s ease forwards;
        opacity: 0;

        .icon-container {
                display: flex;
                flex: 1;
                width: 100%;
                align-items: center;
                justify-content: center;
        }

        svg {
                max-width: 30%;
                aspect-ratio: 1;
                align-self: center;
                justify-self: center;
        }

        .tech-name {
                padding: 0.25rem;
                text-align: center;
                border-top: solid 1px #111;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                background-color: rgba(0, 0, 0, 0.25);
                width: 100%;
        }
}

.tech-stack-item:hover {
        translate: 0px -4px;

        .tech-name {
                background-color: rgba(0, 0, 0, 0.5);
                color: #eee;
        }
}

.not-found {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;

        img {
                width: clamp(300px, 80%, 400px);
                border-radius: 12px;
        }

        p {
                font-size: 16px;
                background-color: rgba(0, 0, 0, 0.5);
                padding: 0.5rem 1rem;
                border-radius: 4px;
                border: solid 1px #333;
        }
}

.section-description {
        margin-bottom: 1rem;
        font-size: 14px;
        text-align: center;
        text-wrap: balance;
}

section.education,
section.work-section {

        animation: swipe-in 0.5s ease forwards;
        opacity: 0;
}

.education-card {
        margin-top: 2rem;
}

.education-card .start,
.education-card .end {
        width: 40px !important;
}

.education-card,
.work-card {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        max-width: 500px;
        animation: swipe-in 0.5s ease forwards;
        opacity: 0;
        background-size: cover;
        overflow: hidden;
        padding-bottom: 0.5rem;

        .header {
                text-align: center;
        }

        &:hover {
                img {
                        scale: 1.05;
                }
        }

        .image-container {
                position: relative;
                border-radius: 12px;
                overflow: hidden;
                background-color: rgba(0, 0, 0, 0.4);
                min-height: 200px;
                max-height: 250px;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

                .image-caption {
                        position: absolute;
                        bottom: 0;
                        right: 50%;
                        transform: translateX(50%);
                        background-color: rgba(0, 0, 0, 0.5);
                        padding: 0.5rem 1rem;
                        backdrop-filter: blur(50px);
                        box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.1);
                        border-radius: 12px 12px 0 0;
                        white-space: nowrap;
                        color: #eee;
                }
        }

        .banner-image {
                width: 100%;
                object-fit: cover;
                border-radius: 12px;
                margin: 0;
                display: block;
                min-height: 100%;
        }

        .education-card-body {
                display: flex;
                flex-direction: column;
                padding: 0.25rem;

                p {
                        font-size: 14px;
                        color: #eee;
                        line-height: 1.75rem;
                }
        }
}

.medium-zoom-image--opened {
        max-height: none !important;
        aspect-ratio: unset !important;
        width: auto !important;
        object-fit: cover;
        z-index: 999;
        border-radius: 4px;
}

.work-section {
        .work-grid {
                position: relative;
                /* text-align: center; */
                margin-block: 2rem;
                gap: 2rem;
                display: flex;
                flex-direction: column;
                align-items: center;
        }


}

.date-sticker {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        gap: 1rem;
        margin-block: 0.25rem;

        .start,
        .end {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: #ddd;
                width: 60px;
        }

        .middle-separator {
                background-color: rgba(255, 255, 255, 0.3);
                height: 1.5px;
                border-radius: 9px;
                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .starter,
                .ender {
                        width: 10px;
                        border-radius: 9px;
                        height: 3px;
                        background-color: rgba(255, 255, 255, 0.75);
                }
        }
}

.highlighted {
        color: #fff;
        font-weight: 500;
}

.mobile-indicator {
        position: absolute;
        /* top: 1rem; */
        /* left: 50%;
        transform: translateX(-50%); */
        padding: 0.25rem 0.5rem;
        height: 40px;
        border-bottom: solid 1px #222;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #ddd;
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        z-index: 10;
        font-weight: bold;
}

@media screen and (max-width: 768px) {
        .mobile-indicator {
                display: inline-flex !important;
        }

        .active-section {
                display: none !important;
        }

        .navbar {
                top: auto;
                bottom: 1rem;
        }

        .navbar ul {
                height: 60px;
                padding-inline: 1rem;
                background-color: rgba(255, 255, 255, 0.25);
        }

        .navbar .icon.active {
                background-color: #ddd;
                color: #222;
        }

        .navbar:hover {
                top: auto;
                bottom: 1rem;
        }

        .main::after {
                background-size: 1dvw 1dvw;
        }

        .cool-body {
                padding-bottom: 6rem;
                padding-top: 4rem;
        }
}

::-webkit-scrollbar {
        display: none;
}

.show-assignments-control {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: end;
        width: 100%;
}

.react-switch-bg {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}